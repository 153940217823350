import InView from 'inview';

let locale = "en";

import en from '.././lang/en.json'
import ko from '.././lang/ko.json'
// import ar from '.././lang/ar.json'
// import jp from '.././lang/jp.json'
import es from '.././lang/es.json'
import ru from '.././lang/ru.json'
import fr from '.././lang/fr.json'
import de from '.././lang/de.json'
import it from '.././lang/it.json'
import pt from '.././lang/pt.json'
import pl from '.././lang/pl.json'

const translations = {
    "en": en,
    "ko": ko,
    // "ar": ar,
    // "jp": jp,
    "es": es,
    "ru": ru,
    "fr": fr,
    "de": de,
    "it": it,
    "pt": pt,
    "pl": pl,
};

const translatedNames = {
    "en": 'English',
    "ko": '한국어',
    // "ar": 'العربية',
    // "jp": '日本語',
    "es": 'Español',
    "ru": 'Русский',
    "fr": 'Français',
    "de": 'Deutsch',
    "it": 'Italiano',
    "pt": 'Português',
    "pl": 'Polski',
};

const languages = [
    "en",
    "ko",
    // "ar",
    // "jp",
    "es",
    "ru",
    "fr",
    "de",
    "it",
    "pt",
    "pl"
];


// For now translate faq page in here
const faqLang = location.pathname.split('/faq-')[1]


document.addEventListener('DOMContentLoaded',function() {

    const dropdown = document.getElementsByClassName('js-lang');

    const getNavigatorLanguage = () => (
        navigator.languages && navigator.languages.length) ? 
        navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';

    const changeOption = function() {        

        for (let i = 0; i < dropdown.length; i++) {
            dropdown[i].addEventListener('change', function(e) {
                setLocale(e.currentTarget.value, true);
                updateLanguageBoxText()
            })
        }
    };


    function updateLanguageBoxText() {
        for (let j = 0; j < dropdown.length; j++) {
            const key = document.getElementsByClassName('js-lang')[0].classList[document.getElementsByClassName('js-lang')[0].classList.length - 1]
            dropdown[j].previousElementSibling.children[0].innerText = translatedNames[key]
        }
    }

    function setLocale(newLocale, clicked=false) {
        if (faqLang && !clicked) {
            newLocale = faqLang
        } else if (faqLang && clicked) {
            location.pathname = `/faq-${newLocale}`
        }
        for (let i = 0; i < dropdown.length; i++) {
            
            dropdown[i].classList.add(newLocale);
            dropdown[i].value = newLocale;

            let cs = dropdown[i].parentNode;
            cs.removeAttribute('class');
            cs.classList.add('custom-select-container', 'customSelect');
            cs.classList.add(newLocale);

        }

        locale = newLocale;

        localStorage.setItem('lang', newLocale);
        translatePage();
    }

    function translatePage() {
        document
          .querySelectorAll("[data-i18n-key]")
          .forEach(translateElement);
        // remove language activated by FAQ page
        if (faqLang) {
            localStorage.removeItem('lang')
        }
      }

    function translateElement(element) {
        const key = element.getAttribute("data-i18n-key");

        const translation = translations[locale][key];
        if (element.nodeName == "INPUT" || element.nodeName == "TEXTAREA") {
            element.placeholder = translation;
        } else if (element.nodeName == "IMG") {
            element.src = translation;
        } else {
            element.innerHTML = translation;
        }
    }


    dropdown ? changeOption() : false;
    let lang = getNavigatorLanguage().substring(0,2).toLowerCase()
    locale = localStorage.getItem('lang') || (languages.includes(lang) ? lang : 'en');
    setLocale(locale);
    translatePage();
    updateLanguageBoxText()

}, false);
