(function() {

    const WebFont = require('webfontloader');

    WebFont.load({

        google: {
            families: ['DM+Sans:400,500,600,700:latin-ext']
        }
    });
})();

