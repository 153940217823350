import css from './sass/style.scss';

require('./js/clipboard.js');
require('./js/fonts.js');
require('./js/polyfills.js');
require('./js/helpers.js');
require('./js/init.js');
require('./js/inview.js');
require('./js/ismobile.js');
require('./js/lang.js');
require('./js/nav.js');
require('./js/validate.js');

require('./img/contact/placeholder.webp');
require('./img/contact/lady.webp');
require('./img/contact/lady@2x.webp');


require('./img/download/appstore.webp');
require('./img/download/appstore@2x.webp');
require('./img/download/googleplay.webp');
require('./img/download/googleplay@2x.webp');

require('./img/download/appstore.png');
require('./img/download/appstore@2x.png');
require('./img/download/googleplay.png');
require('./img/download/googleplay@2x.png');

require('./img/how/01.webp');
require('./img/how/01@2x.webp');
require('./img/how/02.webp');
require('./img/how/02@2x.webp');
require('./img/how/03.webp');
require('./img/how/03@2x.webp');

require('./img/hero/illu-text-en.svg');
require('./img/hero/illu-text-jp.svg');
require('./img/hero/illu-text-ar.svg');
require('./img/hero/illu-text-de.svg');
require('./img/hero/illu-text-es.svg');
require('./img/hero/illu-text-fr.svg');
require('./img/hero/illu-text-it.svg');
require('./img/hero/illu-text-ko.svg');
require('./img/hero/illu-text-pt.svg');
require('./img/hero/illu-text-ru.svg');
require('./img/hero/illu-text-pl.svg');
require('./img/hero/illu-text@2x-en.png');
require('./img/hero/illu-text@2x-jp.png');
require('./img/hero/illu-text@2x-ar.png');
require('./img/hero/illu-text@2x-de.png');
require('./img/hero/illu-text@2x-es.png');
require('./img/hero/illu-text@2x-fr.png');
require('./img/hero/illu-text@2x-it.png');
require('./img/hero/illu-text@2x-ko.png');
require('./img/hero/illu-text@2x-pt.png');
require('./img/hero/illu-text@2x-ru.png');
require('./img/hero/illu-text@2x-pl.png');

require('./img/icons/favicon.ico');

require('./img/download/google/google-play-badge-ar.svg');
require('./img/download/google/google-play-badge-de.svg');
require('./img/download/google/google-play-badge-en.svg');
require('./img/download/google/google-play-badge-es.svg');
require('./img/download/google/google-play-badge-fr.svg');
require('./img/download/google/google-play-badge-it.svg');
require('./img/download/google/google-play-badge-jp.svg');
require('./img/download/google/google-play-badge-ko.svg');
require('./img/download/google/google-play-badge-pl.svg');
require('./img/download/google/google-play-badge-pt.svg');
require('./img/download/google/google-play-badge-ru.svg');

require('./img/download/apple/Download_on_the_App_Store_Badge_AR.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_DE.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_EN.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_ES.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_FR.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_IT.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_JP.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_KO.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_PL.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_PT.svg');
require('./img/download/apple/Download_on_the_App_Store_Badge_RU.svg');