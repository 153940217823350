import ClipboardJS from 'clipboard';
import "primer-tooltips/index.scss";

document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-copy2clip')[0];

    const init = function() {
        const clipboard = new ClipboardJS(el);
        
        clipboard.on('success', function(e) {
            el.classList.add('tooltipped', 'tooltipped-s');
            e.clearSelection();
        });
        
        el.addEventListener('mouseout', function() {
            el.classList.remove('tooltipped', 'tooltipped-s');
        })
    };

    el ? init() : false;

}, false);
