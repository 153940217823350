const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded',function() {

    const nav = document.getElementsByClassName('js-nav')[0],
          hamburger = document.getElementsByClassName('js-hamburger')[0],
          menu = document.getElementsByClassName('js-menu')[0];

    const init = function() {

        const hideMenu = function() {
            nav.classList.remove('is-visible');
            hamburger.classList.remove('is-active');
            
            document.removeEventListener('click', clickOutside);
            
            setTimeout(function() {
                nav.classList.remove('is-block');
                nav.classList.remove('is-animated');
            }, 400);
            
            enableBodyScroll(menu);
        };

        const showMenu = function() {
            
            nav.classList.add('is-block');
            hamburger.classList.add('is-active');

            setTimeout(function() {
                nav.classList.add('is-visible');
                document.addEventListener('click', clickOutside);
                nav.classList.add('is-animated');
            }, 100);            

            disableBodyScroll(menu);
        };

        const toggleMenu = function(e) {
            nav.classList.contains('is-visible') ? hideMenu() : showMenu();
        };
        
        const clickOutside = function(e) {
            if (!e.target.closest('.js-nav')) {
                hideMenu();
        	}
        };

        // Click event
        hamburger.addEventListener('click', toggleMenu);

        // Hide menu on ESC
        document.addEventListener('keydown', function(evt) {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });
        
        const checkWindowSize = function() {
            if (window.innerWidth > 500) {
                document.body.getAttribute('style') ? hideMenu() : false;
                //window.removeEventListener('resize', checkWindowSize);
            }
        };
        
        window.addEventListener('resize', checkWindowSize);
    };

    nav ? init() : false;

}, false);