import validate from 'validate.js';
import toastr from 'toastr'
import 'toastr/build/toastr.css';

import en from '.././lang/en.json'
import ko from '.././lang/ko.json'
import ar from '.././lang/ar.json'
import jp from '.././lang/jp.json'
import es from '.././lang/es.json'
import ru from '.././lang/ru.json'
import fr from '.././lang/fr.json'
import de from '.././lang/de.json'
import it from '.././lang/it.json'
import pt from '.././lang/pt.json'
import pl from '.././lang/pl.json'

const translations = {
    "en": en,
    "ko": ko,
    "ar": ar,
    "jp": jp,
    "es": es,
    "ru": ru,
    "fr": fr,
    "de": de,
    "it": it,
    "pt": pt,
    "pl": pl,
};

const languages = [
    "en",
    "ko",
    "ar",
    "jp",
    "es",
    "ru",
    "fr",
    "de",
    "it",
    "pt",
    "pl",
];


export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


function setCookie(c_name,c_value, exdays) {
    var exdate=new Date();
    exdate.setDate(exdate.getDate() + exdays);
    document.cookie=encodeURIComponent(c_name)
        + "=" + encodeURIComponent(c_value)
        + (!exdays ? "" : "; expires="+exdate.toUTCString())
        + "; path=/";
}

function setAuthTokenHeader(request) {
    const authToken = getCookie('authToken')
    if (authToken) {
        request.setRequestHeader(
            "Authorization",
            `Token ${authToken}`
        )
    }
    return request
}


(function(window, document, wp, undefined) {
    
    const Validators = function() {
        return {
        	contactForm: contactForm,
        	signupForm: signupForm,
            resetPasswordForm: resetPasswordForm,
            resetEmailForm: resetEmailForm,
            confirmEmailForm: confirmEmailForm,
            loginForm: loginForm,
            bankAccountForm: bankAccountForm,
            payoutForm: payoutForm,
        };
    }; 
    
    const init = function(el, constraints, url, success_message, success_url, recaptcha_action) {

        const form = el;
        
        form.addEventListener("submit", function(ev) {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        // Hook up the inputs to validate on the fly
        const inputs = form.querySelectorAll("[required]");

        for (let i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", function(ev) {
                const errors = validate(form, constraints, { fullMessages: false }) || {};
                console.clear();
                console.log(errors);
                showErrorsForInput(this, errors[this.name])
            });
        }

        function handleFormSubmit(form, input) {
            // validate the form against the constraints
            const errors = validate(form, constraints, { fullMessages: false });
            console.log(errors);
            // then we update the form to reflect the results
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            } else {
                // console.log(errors);
            }
        };
        
        // Updates the inputs with the validation errors
        function showErrors(form, errors) {
            // We loop through all the inputs and show the errors for that input
            _.each(form.querySelectorAll("[required]"), function(input) {
            // Since the errors can be null if no errors were found we need to handle
            // that
            showErrorsForInput(input, errors && errors[input.name]);
            });
        };

        // Shows the errors for a specific input
        function showErrorsForInput(input, errors) {
            // This is the root of the input
            const formGroup = closestParent(input.parentNode, "form-row")
            // Find where the error messages will be insert into
            , messages = formGroup.querySelector(".js-messages");
            // First we remove any old messages and resets the classes
            resetFormGroup(formGroup);
            // If we have errors
            if (errors) {
                // we first mark the group has having errors
                formGroup.classList.add("has-error");
                // then we append all the errors
                _.each(errors, function(error) {
                    addError(messages, error);
                });
            } else {
                // otherwise we simply mark it as success
                formGroup.classList.add("has-success");
            }
        };

        // Recusively finds the closest parent that has the specified class
        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        };
        
        function resetFormGroup(formGroup) {
            // Remove the success and error classes
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
            // and remove any old messages
            _.each(formGroup.querySelectorAll(".help-block.error"), function(el) {
                el.parentNode.removeChild(el);
            });
        }
        
        // Adds the specified error with the following markup
        // <p class="help-block error">[message]</p>
        function addError(messages, error) {
            const block = document.createElement("p");
            block.classList.add("help-block");
            block.classList.add("error");
            block.innerText = error;
            messages.appendChild(block);
        }

        function startAjax() {
            form.querySelector('button').style.display = 'none';
            form.querySelector('.lds-ripple').style.display = 'inline-flex';
            form.querySelector('.form-message').style.display = 'none';
        }

        function finishAjax() {
            form.querySelector('button').style.display = 'inline-flex';
            form.querySelector('.lds-ripple').style.display = 'none';
        }

        function submitForm() {
            const CAPTCHA_KEY = process.env.CAPTCHA_KEY;
            toastr.options = {
                "positionClass": "toast-bottom-right",
                "timeOut": "10000",
            }
            grecaptcha.ready(function() {
                grecaptcha.execute(
                    CAPTCHA_KEY, 
                    {action: recaptcha_action}
                ).then(function(token) {
                    document.getElementById('recaptcha').value = token;
                    if (document.getElementById('language')) {
                        document.getElementById('language').value = localStorage.getItem('lang');
                    }
                    sendApiReqest()
                });
            });
        }

        function sendApiReqest() {
            let xmlhttp = new XMLHttpRequest();
            const API_URL = process.env.API_URL;
            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
                    if (xmlhttp.status == 201 || xmlhttp.status == 200) {
                        if (xmlhttp.responseText) {
                            parseAndAssignAuthToken(JSON.parse(xmlhttp.responseText))
                        }
                        toastr.success(success_message)
                        setTimeout(function() {
                            window.location.href = success_url
                        }, 10000);
                    } else {
                        if (xmlhttp.status == 400) {
                            showErrors(form, JSON.parse(xmlhttp.responseText) || {});
                        }
                        toastr.error('There was an error sending the form. Please try again later.')
                        finishAjax();
                    }
                }
            };

            xmlhttp.open("post", API_URL + url, true);
            xmlhttp = setAuthTokenHeader(xmlhttp)
            let FD = new FormData(form);  
            xmlhttp.send(FD);
        }

        function sendServerReqest() {
            let xmlhttp = new XMLHttpRequest(), action = form.getAttribute('action');

            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
                    if (xmlhttp.status == 200) {
                        toastr.success(xmlhttp.responseText)
                    } else {
                        toastr.error(translations[locale]['error'])
                    }
                    finishAjax();
                }
            };

            xmlhttp.open("post", action, true);
            // xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            let FD = new FormData(form);
            console.log(FD);
            xmlhttp.send(FD);
        }

        function showMessage(message) {
            let container = form.querySelector('.form-message');
            container.style.display = 'block';
            container.innerHTML = message;
        }
        
        function showSuccess() {
            startAjax();
            submitForm();
        }

        function parseAndAssignAuthToken(data) {
            if ('token' in data) {
                setCookie('authToken', data['token'])
            }
        }

    };
    
    // Init
    
    const contactForm = function() {
        const url =  'api/contact/add/';
        const success_message = translations[locale]['contact-3'];
        const success_url = '/contact';
        const recaptcha_action = 'contact';
        const constraints = {
            email: {
                presence: { message: translations[locale]['contact-4'] },
                email: { message: translations[locale]['contact-5'] }
            },

            name: {
                presence: { message: translations[locale]['contact-4'] },
                length: {
                    minimum: 3,
                    message:  translations[locale]['contact-6']
                },
            }, 
            
            message: {
                presence: { message: translations[locale]['contact-4'] },
                length: {
                    minimum: 3,
                    message: translations[locale]['contact-6']
                },
            }, 
            
            "confirm_email": {
                presence: { message: translations[locale]['contact-4'] },
                equality: {
                    attribute: "email",
                    message: translations[locale]['contact-7']
                }
            },
        }; 
        
        init(document.getElementsByClassName("js-contactForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };

    const resetPasswordForm = function() {

        const url =  'api/reset_password/';
        const success_message = translations[locale]['reset-password-4'];
        const success_url = '/';
        const recaptcha_action = 'reset_password';
        const constraints = {
            password: {
                presence: { message: translations[locale]['reset-password-5'] },
                length: {
                    minimum: 8,
                    message: translations[locale]['reset-password-6']
                },
            },

            "confirm_password": {
                presence: { message: translations[locale]['reset-password-5'] },
                equality: {
                    attribute: "password",
                    message: translations[locale]['reset-password-7']
                }
            },
        }; 
        
        init(document.getElementsByClassName("js-resetPasswordForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };
    
    const signupForm = function() {

        let url = 'api/entry/add/';
        let referral = getCookie('referral')
        if (referral) {
            url += `?referral=${referral}`
        }
        const success_message = translations[locale]['signup-9'];
        const success_url = '/partners';
        const recaptcha_action = 'signup';
        const constraints = {
            email: {
                presence: { message: translations[locale]['signup-10'] },
                email: { message: translations[locale]['signup-11'] }
            },
            
            followers: {
                numericality: {
                  onlyInteger: true,
                  greaterThan: 0,
                  message: translations[locale]['signup-12'],
                },
                presence: { message: translations[locale]['signup-10'] }
            },

            name: {
                presence: { message: translations[locale]['signup-10'] },
                length: {
                    minimum: 3,
                    message: translations[locale]['signup-13']
                },
            }, 
            
            tel: {
                presence: false,
                format: {
                    pattern: "[0-9()+ ,.'-]+",
                    flags: "i",
                    message: translations[locale]['signup-14']
                }
            },
            
            url: {
                presence: { message: translations[locale]['signup-10'] }
            },
        }; 
        
        init(document.getElementsByClassName("js-signupForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };

    const resetEmailForm = function() {

        const url = 'api/reset_email/';
        const success_message = translations[locale]['reset-email-4'];
        const success_url = '/';
        const recaptcha_action = 'reset_email';
        const constraints = {
            email: {
                presence: { message: translations[locale]['reset-email-5'] },
                email: { message: translations[locale]['reset-email-6'] }
            },

            "confirm_email": {
                presence: { message: translations[locale]['reset-email-5'] },
                equality: {
                    attribute: "email",
                    message: translations[locale]['reset-email-7']
                }
            },
        }; 
        
        init(document.getElementsByClassName("js-resetEmailForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };
    
    const confirmEmailForm = function() {

        const url =  'api/confirm_email/';
        const success_message = translations[locale]['confirm-email-2'];
        const success_url = '/';
        const recaptcha_action = 'confirm_email';
        const constraints = {}; 
        
        init(document.getElementsByClassName("js-confirmEmailForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };

    const loginForm = function() {

        let url = 'api/token-auth/';

        const success_message = translations[locale]['login-7'];
        const success_url = '/affiliate-profile/';
        const recaptcha_action = 'login';
        const constraints = {};

        init(document.getElementsByClassName("js-loginForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };

    const bankAccountForm = function() {

        let url = 'payouts/api/bank-account/set/';

        const success_message = translations[locale]['bank-19'];
        const success_url = '/affiliate-profile/';
        const recaptcha_action = 'bank_account';
        const constraints = {
            "holder_name": {
                presence: {message: translations[locale]['bank-20']},
                length: {
                    maximum: 80,
                    message: translations[locale]['bank-21']
                }
            },
            "address_line1": {
                presence: {message: translations[locale]['bank-20']},
                length: {
                    maximum: 80,
                    message: translations[locale]['bank-21']
                }
            },
            "address_postal_code": {
                presence: {message: translations[locale]['bank-20']},
                length: {
                    maximum: 80,
                    message: translations[locale]['bank-21']
                }
            },
            "address_city": {
                presence: {message: translations[locale]['bank-20']},
                length: {
                    maximum: 80,
                    message: translations[locale]['bank-21']
                }
            },
            "address_country": {
                presence: {message: translations[locale]['bank-20']},
                length: {
                    maximum: 80,
                    message: translations[locale]['bank-21']
                }
            },
            "iban": {
                presence: {message: translations[locale]['bank-20']},
                length: {
                    maximum: 80,
                    message: translations[locale]['bank-21']
                }
            },
            "bic": {
                presence: {message: translations[locale]['bank-20']},
                length: {
                    maximum: 80,
                    message: translations[locale]['bank-21']
                }
            },
        };

        init(document.getElementsByClassName("js-bankAccountForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };

    const payoutForm = function() {

        let url = 'payouts/api/payout/new/';

        const success_message = translations[locale]['payout-19'];
        const success_url = '/affiliate-profile/';
        const recaptcha_action = 'bank_account';
        const constraints = {};

        init(document.getElementsByClassName("js-payoutForm")[0], constraints, url, success_message, success_url, recaptcha_action);
    };
    const getNavigatorLanguage = () => (
        navigator.languages && navigator.languages.length) ? 
        navigator.languages[0] : navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';

    wp.Validators = new Validators();
    let lang = getNavigatorLanguage().substring(0,2).toLowerCase()
    let locale = localStorage.getItem('lang') || (languages.includes(lang) ? lang : 'en');

    document.getElementsByClassName('js-contactForm')[0] ? wp.Validators.contactForm() : false;
    document.getElementsByClassName('js-signupForm')[0] ? wp.Validators.signupForm() : false;
    document.getElementsByClassName('js-resetPasswordForm')[0] ? wp.Validators.resetPasswordForm() : false;
    document.getElementsByClassName('js-resetEmailForm')[0] ? wp.Validators.resetEmailForm() : false;
    document.getElementsByClassName('js-confirmEmailForm')[0] ? wp.Validators.confirmEmailForm() : false;
    document.getElementsByClassName('js-loginForm')[0] ? wp.Validators.loginForm() : false;
    document.getElementsByClassName('js-bankAccountForm')[0] ? wp.Validators.bankAccountForm() : false;
    document.getElementsByClassName('js-payoutForm')[0] ? wp.Validators.payoutForm() : false;



}(window, document, window.wp = window.wp  || {}));
